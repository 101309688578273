import React from "react"

const Footer = () => (
  <footer className="mt-20 bg-gray-800 text-gray-100 -mx-16 py-10">
    <div className="grid grid-cols-1 md:grid-cols-3 col-gap-4 row-gap-6 md:col-gap-10 md:row-gap-10 px-16 py-10">
      <div className="text-base">
        <img src="/logo.svg" alt="logo" className="h-10" />
        <p className="py-2 leading-snug">
          Bsccsit is an online learning platform that is designed for BSCCSIT
          majors specifically from Tribhuvan University. Nevertheless, anybody
          interested can refer to the contents for educational purposes.
        </p>
        <p className="text-sm py-2">
          © {new Date().getFullYear()},{` `}
          <a href="https://www.bsccsit.com">bsccsit.com</a>
        </p>
      </div>
      <div>
        <h3 className="pb-2 font-semibold">CONNECT</h3>
        <ul className="space-y-1">
          <li>
            <a
              href="https://www.facebook.com/bsccsitdotcom"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/bsccsitdotcom"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>LinkedIn</li>
          <li>Instagram</li>
        </ul>
      </div>
      <div>
        <h3 className="pb-2 font-semibold">MORE</h3>
        <ul className="space-y-1">
          <li>Terms</li>
          <li>Privacy</li>
          <li>Contact</li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer
